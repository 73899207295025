import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useDataState, useDataDispatch } from '../DataStore/DataStore';
import "./TopNav.scss";

const TopNav = () => {
  const { showMobileNav } = useDataState();
  const dispatch = useDataDispatch();
  const [mobileNavClass, setMobileNavClass] = useState('hide');

  useEffect(() => {
    if (showMobileNav) {
      setMobileNavClass('show');
    }
    else {
      setMobileNavClass('hide');
    }
  }, [showMobileNav]);

  return (
    <React.Fragment>
      <nav>
        <ul className="top-nav">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog/">Blog</Link></li>
          <li><Link to="/the-cards/">The Cards</Link></li>
          <li>
          <Link to="/readings/">Readings</Link>
            <ul className="subnav">
              <li><Link to="/readings/my-reading-style/">My Reading Style</Link></li>              
              <li><a href="tel:+12088418387" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Call to schedule</a></li>
              <li><a href="sms:+12088418387" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Text to schedule</a></li>
              <li><Link to="/contact/">Email to schedule</Link></li>
            </ul>
          </li>
          <li>
            <a href="https://visionaryroads.com/active-dreaming-circle/">Dream Circle</a>
          </li>
          <li><Link to="/contact/">Contact Me</Link></li>
        </ul>
        <ul className={`mobile-nav ` + mobileNavClass}>
        <li><Link to="/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Home</Link></li>
          <li><Link to="/blog/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Blog</Link></li>
          <li><Link to="/the-cards/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>The Cards</Link></li>
          <li>
          <Link to="/readings/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Readings</Link>
            <ul className="subnav">
              <li><Link to="/readings/my-reading-style/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>My Reading Style</Link></li>
              <li><a href="tel:+12088418387" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Call to schedule</a></li>
              <li><a href="sms:+12088418387" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Text to schedule</a></li>
              <li><Link to="/contact/">Email to schedule</Link></li>
            </ul>
          </li>
          <li>
            <a href="https://visionaryroads.com/active-dreaming-circle/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Dream Circle</a>
          </li>
          <li><Link to="/contact/" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}>Contact Me</Link></li>
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default TopNav;