import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Callout = () => {
  return (
    <React.Fragment>
      <div className="callout-grid">
        <div className="callout-item">
          <Link to="/blog/">
            <div className="callout-image"><StaticImage src="../../images/handwriting.jpg" alt="Blog" /></div>
            <div className="callout-title">Tarot Blog</div>
            <div className="callout-description">Adventures :: Reflections</div>
          </Link>
        </div>
        <div className="callout-item">
          <Link to="/the-cards/">
            <div className="callout-image"><StaticImage src="../../images/tarot_arch2.jpg" alt="The Cards" /></div>
            <div className="callout-title">The Cards</div>
            <div className="callout-description">Keywords :: Suggestions</div>
          </Link>
        </div>
        <div className="callout-item">
          <Link to="/readings/">
            <div className="callout-image"><StaticImage src="../../images/candle.jpg" alt="Tarot Readings" /></div>
            <div className="callout-title">Tarot Readings</div>
            <div className="callout-description">Insight :: Inspiration</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Callout
