import React from "react";
import { DataStore } from "./src/components/DataStore/DataStore";
import Layout from "./src/components/Layout/Layout";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/libre-baskerville/700.css";
import "@fontsource/libre-baskerville/400.css";

export const wrapRootElement = ({ element }) => {
  return (<DataStore>{element}</DataStore>);
};

export const wrapPageElement = ({ element, props }) => {
  return (<Layout {...props}>{element}</Layout>);
};