import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "./Breadcrumbs.scss";

const Breadcrumbs = (props) => {
  const pathname = props.pageInfo.location.pathname;
  const mdxTitle = ((props.pageInfo.data !== undefined) && (props.pageInfo.data.mdx !== undefined)) ? props.pageInfo.data.mdx.frontmatter.title : null;
  const [categoryJsx, setCategoryJsx] = useState(null);  
  const [articleJsx, setArticleJsx] = useState(null);
  const [categoryLabel, setCategoryLabel] = useState(null);

  useEffect(() => {
    const pathSplit = pathname.split('/').filter(i => i);    
    const isCategory = pathSplit.length < 2 ? true : false;
    switch (pathSplit[0]) {
      case 'blog':
        setCategoryLabel('Blog');
        break;
      case 'the-cards':
        setCategoryLabel('The Cards');
        break;
      case 'readings':
        setCategoryLabel('Readings');
        break;
      case 'contact':
        setCategoryLabel('Contact Me');
        break;
      case 'privacy-policy':
        setCategoryLabel('Privacy Policy');
        break;
      default:
        setCategoryLabel(null);
    }
    const categoryItem = isCategory ? categoryLabel : <Link to={ '/' + pathSplit[0] + '/'}>{categoryLabel}</Link>;
    setCategoryJsx(<span className="breadcrumb-item">{categoryItem}</span>);

    const isCategoryReadings = (pathSplit[0] === 'readings') ? true : false;

    if (isCategoryReadings && pathSplit[1] === 'my-reading-style') {
      setArticleJsx(<span className="breadcrumb-item">My Reading Style</span>);
    }
    else {
      const articleItem = isCategory ? null : <span className="breadcrumb-item">{mdxTitle}</span>;
      setArticleJsx(articleItem);
    }
  }, [pathname, categoryLabel, mdxTitle]);
  
  return (
    <div className="breadcrumbs">
      <span><Link to="/">Home</Link></span>
      { categoryJsx }
      { articleJsx }
    </div>
  )
}

export default Breadcrumbs
