import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./About.scss";

const About = () => {
  return(
    <div className="about-box">
      <h3>About</h3>
      <div>
        <StaticImage className="about-image" src="../../images/headshot_greenbelt.jpg" alt="Timothy Yuen" />
      </div>
      <div>
        <p>Hi! My name is Tim. I am a tarot guide and enthusiast, teacher of Active Dreaming, and playful philosopher with a mystical bent. This site is a way for me to share my love of tarot with you, philosophize a bit, and offer inspiration to live a meaningful life. It is a continual work in process.</p>
        <p>There are many dimensions to tarot—and so many possibilities for working with it—that each person's journey with tarot is unique. Here you will find my experiences, perspectives, and reflections as I continue to learn and practice, and ways to engage me more directly through readings and workshops.</p>
        <p>Welcome, and have a wonder-filled time with this site!</p>
      </div>
    </div>
  )
}

export default About;