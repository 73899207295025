import React, {createContext, useReducer, useContext } from 'react';

const initialData = {
  showMobileNav: false,
  showContent: true
};
const DataState = createContext();
const DataDispatch = createContext();

const reducer = (state, action) => {
  //console.log(action);
  switch(action.type){
    case 'setShowMobileNav':
      return {
        ...state,
        showMobileNav: action.value
      };
    case 'setShowContent':
      return {
        ...state,
        showContent: action.value
      };
    default:
      return {
        ...state
      };
  }
}; 

const DataStore = ( {children} ) => {
  const [state, dispatch] = useReducer(reducer, initialData);
  return (
    <DataState.Provider value={state}>
      <DataDispatch.Provider value={dispatch}>
        {children}
      </DataDispatch.Provider>
    </DataState.Provider>
  );
};

const useDataState = () => {
  const state = useContext(DataState);
  return state;
}

const useDataDispatch = () => {
  const dispatch = useContext(DataDispatch);
  return dispatch;
}

export { DataStore, useDataState, useDataDispatch }