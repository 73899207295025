import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "normalize.css";
import "./Layout.scss";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useDataState, useDataDispatch } from '../DataStore/DataStore';
import TopNav from "../TopNav/TopNav";
import About from "../About/About";
import Callout from "../Callout/Callout";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const Layout = ({ children, location }) => {
  const { showMobileNav } = useDataState();
  const dispatch = useDataDispatch();

  return (
    <div className="page-container">
      <div className="splash-image-wrapper">
        <Link to="/" className="splash-image" onClick={() => dispatch({ type: 'setShowMobileNav', value: false })}><StaticImage
          src="../../images/twt_logo_small.png"
          alt="Tarot with Tim"
          loading="lazy"
          backgroundColor="transparent"
          placeholder="none"
          objectFit="contain"
        /></Link>
      </div>
      <div className="mobile-nav-toggle">
        <button className="menu-button" aria-label={showMobileNav ? "Close" : "Menu"} onClick={() => dispatch({ type: 'setShowMobileNav', value: !showMobileNav })}><FontAwesomeIcon icon={ showMobileNav ? faWindowClose : faBars } style={{fontSize: "2rem"}} size="sm" /> <div className="menu-button-label">{showMobileNav ? "Close" : "Menu"}</div></button>
      </div>
      <TopNav />         
      <div className="background-container">
        <div className="content-container">
          {
            location.pathname === '/' ? <Callout /> : <Breadcrumbs pageInfo={children.props} />
          }
          <main className="main-content">{children}</main>
          <section className="sidebar">
            <div className="sidebar-item"><About /></div>
          </section>
        </div>
      </div>
      <footer>
        <div className="footer-grid">
          <div>© {new Date().getFullYear()} Tarot with Tim</div>
          <div className="divider-before">Timothy Yuen</div>
          <div className="divider-before"><Link to="/privacy-policy/">Privacy</Link></div>
        </div>        
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
